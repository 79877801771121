h2 {
  margin-top: 1rem;
  /* Add the desired top margin */
  margin-left: 45rem;
  /* Add the desired left margin */
  font-size: 3rem;
  /* Add the desired font size */
  color: black;
  /* Add the desired text color */
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* Add any additional styles as needed */
}

.label {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 275px;
}

@media only screen and (max-width: 768px) {
  .label {
    margin-top: 265px;
  }
}

.coverr {
  background-image: url('../../images/userprofilebg.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 1%;
  height: 700px;
  background-attachment: local, scroll;
}

@media only screen and (max-width: 768px) {
  .coverr {
    background-color: #EBDE70;
    background-image: none;
  }
}