html,
body {
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  /* text-transform: uppercase; */
  text-decoration: solid;
  /* background: url('../src/images/yellow.gif') no-repeat center center fixed;
  background-size: 100% 100%;  */
  /* background-color: #EBDE70; */
}



/* Add the media query for responsiveness */
@media (max-width: 768px) {
  .chart-container {
    width: 100%;
    height: 200px;
    /* Adjust the height for smaller screens */
  }
}

@media (min-width: 768px) {
  .chart-container {
    width: 100%;
    height: 300px;
    /* Default height for larger screens */
  }
}

/* .row{
  border: solid black;
  padding: 20px 40px ;
} */

.card {
  border: solid #4F4A45;
  background-color: ghostwhite;
  box-shadow: 3px 8px 3px 3px rgb(91, 90, 90);

}

.card-img-top {
  border: solid black;
  border-radius: 10%;
  position: relative;
  right: 12px;
  bottom: 5px;
}


.navbar-brand img {
  height: 75px;
  width: 80px;
  margin-left: 30px;


}

/* css for blessed land academy text */
.lg-3 {
  font-family: fantasy;
  font-size: 20px;
  color: black;
  font-weight: medium;
  transition: all 0.3s ease;
}

/* css for blessed land academy text */
.lg-3:hover {
  font-size: 30px;
  /* Change the font size on hover */
  font-weight: bold;
  /* Change the font weight on hover */
  color: #172536;
  /* Replace with the desired hover color */
  border-color: #172536;
  /* Replace with the desired hover border color */
  border-radius: 30%;
  background-color: #EBDE70;
}

/* Scroll Bar */

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(66, 66, 66, 0.2);
  border: 0px;
  background-clip: padding-box;
  border-radius: 5px;
}

/* Default styles for the navbar */
nav {
  background: rgb(156, 134, 92);
  background: linear-gradient(90deg, rgba(156, 134, 92, 1) 21%, rgba(238, 213, 108, 1) 47%, rgba(236, 211, 108, 1) 48%, rgba(156, 134, 92, 1) 80%);
  border-color: black;
  height: 120px;
  border: hidden;
}

/* the width and length of the navbar */
.navbar-brand {
  padding-top: 15px;
  /* Add more padding to the top */
  padding-bottom: 15px;
  /* Add more padding to the bottom */
  padding-right: 35px !important;
}

/* css for login button in navbar */
#login.login-nav {
  margin-right: 2%;
  font-family: fantasy;
  font-size: 20px;
  font-weight: larger;
  color: black;
  display: inline-block;
  padding: 8px 15px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

#login.login-nav:hover {
  color: #172536;
  border-color: #172536;
  border-radius: 30%;
  background-color: #EBDE70;
  font-size: 30px;
}

/* css for about us button in navbar */
.navbar a.mx-3 {
  margin-right: 2%;
  font-family: fantasy;
  font-size: 20px;
  font-weight: larger;
  color: black;
  display: inline-block;
  padding: 8px 15px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.navbar a.mx-3:hover {
  color: #172536;
  border-color: #172536;
  border-radius: 30%;
  background-color: #EBDE70;
  font-size: 30px;
}

/* css of product button in navbar */
.navbar .ml-3 {
  margin-right: 2%;
  font-family: fantasy;
  font-weight: larger;
  font-size: 20px;
  color: black;
  display: inline-block;
  padding: 8px 15px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.navbar .ml-3:hover {
  color: #172536;
  border-color: #172536;
  border-radius: 30%;
  background-color: #EBDE70;
  font-size: 30px;
}

#request svg {
  /* Change the fill color of the SVG icon */
  fill: black;
  /* Change this color to whatever you want */
}

#cart svg {
  /* Change the fill color of the SVG icon */
  fill: black;
  /* Change this color to whatever you want */
}

/* Media query for responsiveness */


@media screen and (max-width: 768px) {
  nav {
    height: auto;
  }

  .navbar-brand,
  #login.login-nav,
  .navbar a.mx-3,
  .navbar .ml-3 {
    margin-right: 0;
  }

  #login.login-nav,
  .navbar a.mx-3,
  .navbar .ml-3 {
    font-size: 18px;
    padding: 8px 10px;
  }
}


@media screen and (max-width: 1015px) {
  nav {
    height: auto;
  }

  .navbar-brand,
  #login.login-nav,
  .navbar a.mx-3,
  .navbar .ml-3 {
    margin-right: 0;
  }

  #login.login-nav,
  .navbar a.mx-3,
  .navbar .ml-3 {
    font-size: 18px;
    padding: 8px 10px;
  }
}



#sidebar {
  min-width: 100%;
  max-width: 100%;
  background: rgb(245, 175, 132);
  color: #fff;
  transition: all 0.3s;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #232f3e;
}

#sidebar ul.components {
  padding: 5px 0px;
  border-bottom: 1px solid #232f3e;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: white;
  text-decoration: none;
}

#sidebar ul li a:hover {
  color: #232f3e;
  background: #fff;
}

#sidebar ul li.active>a,
#sidebar ul li a.active,
#sidebar ul li a:hover {
  color: #fff;
  background: #232f3e;
}

#sidebar ul li a i {
  margin-right: 0.3rem;
}



.active-step {
  font-style: fantasy;
}


#search_btn {
  background-color: #febd69;
  /* background-color: ; */
}

.input-group {
  width: 90%;
  margin-right: 35%;
}

#search_field {
  height: 2.4rem;
  padding: 1rem;
}

#login_btn,
#view_btn {
  background-color: orange;
  padding: 0.4rem 1.8rem;
  font-family: sans-serif;

}

#cart {
  /* font-size: 1rem; */
  color: blue;
  text-decoration: solid;
}

#cart_count {
  background-color: whitesmoke;
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  color: black;
  font-weight: bold;
  font-size: 0.9rem;
}



#request_count {
  background-color: whitesmoke;
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  color: black;
  font-weight: bold;
  font-size: 0.9rem;
}

x

/* Home page */
#products_heading {
  margin-top: 1.8rem;
  margin-left: 30px;
}



.card {
  height: 100%;
}

.card-title a {
  color: black;
  font-family: fantasy;
}

.card-title a:hover {
  color: #fa9c23;
  text-decoration: none;
}

.card-body {
  padding-left: 0;
}

.card-text {
  font-size: 1.4rem;
}

#view_btn {
  background-color: #fa9c23;
  color: white;
}

.card-img-top {
  width: 200px;
  height: 150px;
}

.ratings {
  font-size: 1.2rem;
  color: #fdcc0d;
}

#no_of_reviews {
  font-size: 0.85rem;
  color: grey;
  margin-left: 0.5rem;
}

/* Footer */
footer {
  margin-top: 8rem;
  color: grey;
  bottom: 0;
  width: 100%;

}

/* Ratings */

.rating-outer {
  display: inline-block;
  position: relative;
  font-family: FontAwesome;
  color: #fdcc0d;
}

.rating-outer::before {
  content: '\f006 \f006 \f006 \f006 \f006';
}

.rating-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.rating-inner::before {
  content: '\f005 \f005 \f005 \f005 \f005';
  color: #f8ce0b;
}

/* Product Details Page */
#product_id {
  color: grey;
  font-size: 0.8rem;
}

#product_price {
  font-size: 2rem;
  font-family: fantasy;
}

#cart_btn,
#review_btn {
  border-radius: 2rem;
  background-color: #fa9c23;
  border: none;
  padding: 0.5rem 2rem;
}

#product_seller {
  color: grey;
  font-size: 0.9rem;
}

#stock_status {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 0.3rem;
}

.redColor {
  color: red;
}

.greenColor {
  color: green;
}

#product_image {
  margin-top: 9rem;
}

/* Loader */
.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}

.loader:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fa9c23;
  border-color: #fa9c23 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Cart */

.cart-item {
  margin: 1.8rem 0rem;
  padding: 0rem 0.7rem;
}

.cart-item a {
  color: grey;
}

#delete_cart_item {
  color: red;
  background: white;
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  border: none;
}

#card_item_price {
  color: #febd69;
  font-weight: bold;
  font-size: 1.4rem;
}

#checkout_btn,
.review-btn {
  background-color: #fa9c23;
  border-color: #fa9c23;
  margin-top: 2rem;
  border-radius: 5rem;
}

#back_btn {
  background-color: #fa9c23;
  border-color: #fa9c23;
  margin-top: 2rem;
  border-radius: 5rem;
}


#adddocu_btn {
  background-color: #5cb85c;
  border-color: #5cb85c;
  margin-top: 2rem;
  border-radius: 5rem;
}


#view_order_details {
  background-color: #fa9c23;
  border-color: #fa9c23;
}

#order_summary {
  border: 1px solid #e3e3e3;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
}

.order-summary-values {
  float: right;
  font-weight: bold;
}

.stockCounter input {
  border: none;
  width: 3rem;
  text-align: center;
}

.plus,
.minus {
  padding: 0.1rem 0.5rem;
}

.stockCounter input::-webkit-outer-spin-button,
.stockCounter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
}

/* Login & Register */

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  font-weight: 700;

}

.wrapper form {
  padding: 2.5rem 3rem;
  border: solid;
  border-radius: 20px;
  background-color: white;
  ;
  font-family: sans-serif;
  border-radius: 25px;
  margin-top: -10%;
}

.wrapper form .btn {
  background: linear-gradient(90deg, rgba(255, 222, 89, 1) 9%, rgba(252, 161, 104, 1) 86%);
  border-color: orange;
  color: black;
  margin-top: 2.5rem;
  box-shadow: 5px 5px 10px 3px #d76e2d;
  font-size: 21px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  border-radius: 25px;

}

.wrapper form a {
  font-size: 0.9rem;
  color: grey;

}

.wrapper form h1 {
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;

}

/* Avatar */
.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 3rem;
  width: 3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-left: -40px;
}

.btn.dropdown-toggle {
  margin-left: 8px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

/* Header User Profile */

.avatar-nav {
  margin-right: 5.0rem;
  height: 2.2rem;
  width: 2.2rem;
}

/* Checkout Steps */

.checkout-progress div {
  box-sizing: border-box;
}

.checkout-progress {
  display: block;
  clear: both;
  margin: 20px auto;
  width: auto;
  font-family: sans-serif;
  overflow: auto;
}

.step {
  margin: 0;
  border: 0;
  letter-spacing: 1px;
  line-height: 30px;
  padding: 5px 10px 5px 15px;
  color: grey;
  text-decoration: none;
  cursor: default;
  font-weight: bold;
  float: left;
  height: auto;
}

.incomplete {
  background: #eeeeee;
}

.active-step {
  background: #fa9c23;
  color: #fff;
}

.triangle-active {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #fa9c23;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-active {
  width: 0;
  float: left;
  border-top: 20px solid #fa9c23;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #fa9c23;
  margin-right: -1px;
}

.triangle-incomplete {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #eeeeee;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-incomplete {
  width: 0;
  float: left;
  border-top: 20px solid #eeeeee;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #eeeeee;
  margin-right: -1px;
}

/* User Profile */

.avatar-profile {
  margin-top: 2rem;
  height: 16rem;
  width: 16rem;
}

.user-info h4 {
  font-weight: 800;
  color: grey;
  margin-top: 2rem;
}

.user-info p {
  font-size: 1.1rem;
}

#edit_profile {
  background: linear-gradient(90deg, rgba(255, 222, 89, 1) 9%, rgba(252, 161, 104, 1) 86%);
  border-color: orange;
  border-radius: 75px 75px;
  margin-left: -4%;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  width: 45%;
  /* Adjust the width of the element */
  font-size: 120%;
  color: black;
  margin-top: 25px !important;

}

/* Confirm Order */

.order-confirm p,
.order-details p {
  margin-left: 1.5rem;
}

.update-btn {
  background-color: #fa9c23;
  border-color: #fa9c23;
  color: white;
}

.update-btn:hover {
  color: white;
}

/* Pagination */
.page-item.active .page-link {
  background-color: #fa9c23;
  border-color: #fa9c23;
}

.page-link {
  color: #fa9c23;
}

.page-link:hover {
  color: #fa9c23;
}

/* Admin Sidebar */

.sidebar-wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: 100vh;
  font-weight: normal;

  margin-top: 0%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  margin-left: 0%;
}

#sidebar {
  min-width: 100%;
  max-width: 100%;
  background: rgba(156, 134, 92, 0.9);
  color: black;
  transition: all 0.3s;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #232f3e;
}

#sidebar ul.components {
  padding: 5px 0px;
  border-bottom: 1px solid #232f3e;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: white;
  text-decoration: none;
}


#sidebar ul li a:hover {
  color: #232f3e;
  background: #fff;
}

#sidebar ul li a i {
  margin-right: 0.3rem;
}

#sidebar ul li.active>a,
a[aria-expanded='true'] {
  color: #fff;

}

a[data-toggle='collapse'] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 120px;
  transform: translateY(-50%);
}

/* ul ul a {
  font-size: 1rem !important;
  padding-left: 40px !important;
  background: #EBDE70;
  border-radius: 25px;
} */

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

/* Products List Admin */
#products_table .btn {
  padding: 0.2rem 0.5rem;
  margin-left: 0.4rem;
}

/* Dashboard */

.card-font-size {
  font-size: 1.5rem;
}

/* Reviews */

.rating {
  margin-top: 10rem;
}

.stars {
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.star {
  display: inline;
  list-style: none;
  font-size: 3rem;
  padding-left: 0.9rem;
  color: #e3e3e3;
}

.star:first-child {
  padding-left: 0;
}

.orange {
  color: #fa9c23;
}

.yellow {
  color: #fdcc0d;
}

.review_user {
  font-size: 0.8rem;
  color: grey;
}


.bg-cyan {
  background-color: cyan;
}

.bg-orangee {
  background-color: #E4C59E;
}

.bg-purplee {
  background-color: #81689D;
}

.bg-bluee {
  background-color: #474F7A;
}

.bg-lightt {
  background-color: #AC87C5;
}

.bg-darkbrownn {
  background-color: #E9C874;
}

.bg-redd {
  background-color: #E8D8C4;
}

.bg-brown {
  background-color: #C6A969;
}


.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  max-width: 100%;
  height: auto;
}