.track-number {
    margin-top: -10px;
}

.track-number {
    margin-top: 100px !important;
    font-size: 23px;
    font-family: Arial, sans-serif;
    font-weight: bold !important;
}


.underline {
    text-decoration: underline;
}

.statuss {
    margin-top: 70px;
    margin-left: 100px;
}

.student-name {
    font-size: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

.student-namee p {
    font-size: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

.students-name p {
    font-size: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    margin-left: 21px;
}

.custom-parent-div {
    margin-left: 80px;
    /* Adjust this value as needed */
    margin-top: -50px;
}

.button-28 {
    appearance: none;
    background-color: transparent;
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    box-sizing: border-box;
    color: #3B3B3B;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    margin: 0 auto;
    min-height: 10px;
    min-width: 0;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 150px;
    will-change: transform;
}

.button-28:disabled {
    pointer-events: none;
}

.button-28:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.button-28:active {
    box-shadow: none;
    transform: translateY(0);
}