.sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    z-index: 999;
    /* Ensure the overlay is on top of other content */
}

.custom-drawer {
    position: fixed;
    top: 132px;
    left: 0;
    width: 250px;
    /* Adjust width as needed */
    height: 100%;
    z-index: 1000;
    /* Ensure the sidebar content is above the backdrop */

}

.custom-drawer {
    transition: width 0.3s ease;
    /* Smooth transition effect */
}

.custom-drawer:hover {
    width: 300px;
    /* Adjust the width of the sidebar on hover */
}

.list-unstyled {
    /* Your CSS styles for the list-unstyled class */
    padding-left: 5px;
    list-style: none;
    font-weight: bold;
    /* Make the font bold */
    font-size: 16px;
    /* Make the font bigger */
    color: #D6CFCE !important;

}

.list-unstyled.components li a {

    color: #D6CFCE;
    font-size: 19px;
    transition: color 0.3s ease;
    /* Add transition for smooth effect */
    border: 1px solid transparent;
    /* Add transparent border */
    padding: 10px;
    /* Add padding */
}

.list-unstyled.components li a:hover {
    color: black;
    /* Change text color on hover */
    background-color: #ebde70;
    border: 2px solid #ebde70;
    /* Add border color and width */
    border-radius: 25px;
    /* Add border radius */
}

.document-link {
    font-size: 10px;
}