/* ForgotPassword.css */
.wrapper-forgot-password {
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    margin: 0;
    padding: 0;
  }
  
  .forgot-password-form {
    border: 2px solid #000000; /* Add border styling here */
    border-radius: 25px; /* Optional: Add border-radius for rounded corners */
    padding: 20px;
    margin-left: -60px;
  }
  
  /* Add any additional styling as needed */
  h1 {
    color: black;
    font-size: 35px;
    text-align: center; /* Center the text */
    margin-bottom: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
  }
