.bouncing-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15vh;
    /* Adjust the height as needed */
    margin-bottom: -5%;
}

.bouncing-text {
    animation: bounce 2s infinite;
    /* You can adjust the duration and iteration count */
    margin-top: 0%;
    text-align: center;
    font-style: italic;
    font-family: fantasy;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-40px);
    }

    60% {
        transform: translateY(-10px);
    }
}