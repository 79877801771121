.footer-style {
  /* display: flex; */
  justify-content: center;
  text-align: center;
  background: rgb(156, 134, 92);
  background: linear-gradient(90deg, rgba(156, 134, 92, 1) 21%, rgba(238, 213, 108, 1) 47%, rgba(236, 211, 108, 1) 48%, rgba(156, 134, 92, 1) 80%);

  padding: 20px;
  margin-top: 0px;
  border: outset;
  border-color: black;
}

/* p {
  color: black;
} */