.guest-header {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 50px;

}


.container-bg {
    background-color: #e8db6e;
    height: 100%;
    margin-top: -40px;
}

.text-guest {
    padding: 80px;
    text-align: justify;

}

.contact-options {
    text-align: center;
    margin-top: 20px;
}