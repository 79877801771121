.custom-column {
    margin-top: 12%;
    margin-bottom: 6%;
    max-width: 80%;
}

.custom-form {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.custom-input-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.custom-input {
    flex: 1;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.custom-input:focus {
    outline: none;
    border-color: #9c865c;
}

.avatar-preview {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
}

.avatar-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.custom-file {
    display: flex;
    align-items: center;
}

.custom-file-input {
    display: none;
}

.custom-file-label {
    background-color: #9c865c;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.custom-btn {
    background-color: #9c865c;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.custom-btn:hover {
    background-color: #7f6e4e;
}