.clas {

    margin-left: 500px;
    margin-top: 200px;
}

@media (max-width: 765px) {
    .clas {
        margin-left: 100px;
        margin-top: 200px;
    }
}

.titlee {
    display: flex;
    margin-left: 490px;
    margin-top: 30px;
    font-size: 40px;
}

.custom-button-width {
    width: 200px;

}

.align-right {
    margin-left: auto;
}

.customm-button-width {
    width: 200px;

}

.align-left {
    margin-right: auto;
}

.button-container {
    display: flex;
}

.button-container button {
    flex: 1;
    margin-right: 10px;
    /* Adjust as needed */
}

.button-container button:last-child {
    margin-right: 0;
    /* Remove margin from the last button */
}

.containerr {
    display: flex;
    margin-left: -700px;

}

/* CSS */
.button-53 {
    background-color: #ebde70;
    border: 0 solid #E5E7EB;
    box-sizing: border-box;
    color: #000000;
    display: flex;
    font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.75rem;
    padding: .75rem 1.65rem;
    position: relative;
    text-align: center;
    text-decoration: none #000000 solid;
    text-decoration-thickness: auto;
    /* width: 50%; */
    /* max-width: 460px; */
    position: relative;
    cursor: pointer;
    transform: rotate(-2deg);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    height: 70px;
    margin-left: 180px;
    margin-top: 10px;
}

.button-53:focus {
    outline: 0;
}

.button-53:after {
    content: '';
    position: absolute;
    border: 1px solid #000000;
    bottom: 4px;
    left: 4px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
}

.button-53:hover:after {
    bottom: 2px;
    left: 2px;
}

@media (max-width: 768px) {
    .button-53 {
        padding: .75rem 3rem;
        font-size: 1.25rem;
        margin-left: 150px;
    }
}