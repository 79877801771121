@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap");

.App-header {
    font-size: 2rem;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 2rem;
}

.carousel-images {
    position: relative;
    border-radius: 10px;
    height: 500px;
    max-width: 1000px;
    margin: auto;
    overflow: hidden;
}

.carousel-images img {
    width: 99%;
    height: 99%;
    border-radius: 8px;
    border: black solid 2px;
}

.slide_direction {
    display: flex;
    justify-content: space-between;
}

.left,
.right {
    background-color: #fb666675;
    color: #fff;
    padding: 10px 8px 8px 13px;
    margin: 0 20px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 10px;
    height: 25px;
    width: 25px;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.carousel-indicator {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.dot {
    background-color: #333;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.active {
    background-color: #fa2020;
}



/* 
.announcement {
    position: relative;
    max-width: 800px;
    margin: auto;
    overflow: hidden;
    border-radius: 10px;
}

.announcement-item {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    border-radius: 10px;
}

.announcement-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
    border-radius: 10px;
}

.announcement-text {
    padding: 20px;
}

h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

p {
    font-size: 18px;
    color: #555;
}

.announcement-direction {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.left,
.right {
    background-color: #fb666675;
    color: #fff;
    padding: 10px 8px 8px 13px;
    border-radius: 50%;
    cursor: pointer;
}

.announcement-indicator {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.dot {
    background-color: #333;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.active {
    background-color: #fa2020;
} */