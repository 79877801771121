.toggle-button {
    --color: brown;
    --color2: rgb(10, 25, 30);
    padding: 0.8em 1.75em;
    background-color: transparent;
    border-radius: 6px;
    border: .3px solid var(--color);
    transition: .5s;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
    font-weight: bold;
    font-size: 17px;
    font-family: 'Roboto', 'Segoe UI', sans-serif;
    text-transform: uppercase;
    color: var(--color);
    display: block;
    margin: 0 auto;
    /* Center the buttons horizontally */
}

.toggle-button::after,
.toggle-button::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    transform: skew(90deg) translate(-50%, -50%);
    position: absolute;
    inset: 50%;
    left: 25%;
    z-index: -1;
    transition: .5s ease-out;
    background-color: var(--color);
}

.toggle-button::before {
    top: -50%;
    left: -25%;
    transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

.toggle-button:hover::before {
    transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

.toggle-button:hover::after {
    transform: skew(45deg) translate(-50%, -50%);
}

.toggle-button:hover {
    color: var(--color2);
}

.toggle-button:active {
    filter: brightness(.9);
    transform: scale(.98);
}

/* Add more styles for other toggle buttons if needed (e.g., product-filter, grade-filter, status-filter) */

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .toggle-button {
        padding: 0.6em 1.25em;
        /* Adjust padding for smaller screens */
        font-size: 14px;
        /* Adjust font size for smaller screens */
    }
}

@media screen and (max-width: 768px) {
    .col-md-3 {
        text-align: center;
        /* Center the contents horizontally */
    }

    .date-input-section {
        display: inline-block;
        /* Make the date input section inline block */
        margin-top: -20px;

    }

    .document-input-section {
        display: inline-block;
        /* Make the date input section inline block */
        margin-top: -20px;
        margin-right: 60px;
    }

    .grade-input-section {
        display: inline-block;
        /* Make the date input section inline block */
        margin-top: -20px;
        margin-right: 75px;
    }

    .status-input-section {
        display: inline-block;
        /* Make the date input section inline block */
        margin-top: -20px;
        margin-right: 75px;
    }

    .product-input-section {
        display: inline-block;
        /* Make the date input section inline block */
        margin-top: -20px;
        margin-right: 80px;
    }
}